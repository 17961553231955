<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>内容管理</a-breadcrumb-item>
    <a-breadcrumb-item>待入库</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 剧名 -->
      <template v-if="column.key === 'name'">
        <img class="cover-img" :src="$pub.CDN_URL(record.s_cover[0].url)" v-if="record && record.s_cover.length">
        <span class="cover-title">{{ record.remark_name }}</span>
      </template>
      <!-- 底价 -->
      <template v-if="column.key === 'money'">
        {{ $pub.KEEP_NUMBER_DECIMAL(record[column.key] / 100, 2) }}
      </template>
      <!-- 分成 -->
      <template v-if="column.key === 'cop_rate'">
        {{ record.cop_type === 2 ?  `${record.cop_rate}%` : '-' }} 
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchInfo(record)">基本信息</a>
        <a class="operation-item" @click="touchSeries(record)">剧集</a>
        <a-popconfirm
          title="确定要入库吗？"
          @confirm="touchStore(record)"
        >
          <a class="operation-item">入库</a>
        </a-popconfirm>
      </template>
    </template>
  </a-table>
  <!-- 基本信息 -->
  <BaseInfo ref="RefBaseInfo" @success="getData"></BaseInfo>
  <!-- 剧集 -->
  <Series ref="RefSeries"></Series>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import Series from './components/Series'
import BaseInfo from './components/BaseInfo'
import { getDramaUnList, storageDrama } from '@/api/operate'

// 基本信息组件实例
let RefBaseInfo = ref(null)
// 剧集组件实例
let RefSeries = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '剧名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '评级',
    dataIndex: 'grade',
    key: 'grade'
  },
  {
    title: '权重',
    dataIndex: 'weight',
    key: 'weight'
  },
  {
    title: '总集数',
    dataIndex: 'total_ep',
    key: 'total_ep'
  },
  {
    title: '授权开始',
    dataIndex: 'copyright_start_at',
    key: 'copyright_start_at'
  },
  {
    title: '授权结束',
    dataIndex: 'copyright_end_at',
    key: 'copyright_end_at'
  },
  {
    title: '底价',
    dataIndex: 'money',
    key: 'money'
  },
  {
    title: '分成',
    dataIndex: 'cop_rate',
    key: 'cop_rate'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数 - onBeforeMount
onBeforeMount(() => {
  getData()
})

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getData()
}

// 获取数据
function getData () {
  isLoading.value = true
  const params = {
    in_storage: 0,
    page: pagination.current,
    page_size: pagination.pageSize,
    // 默认按 id 降序
    key: 'id',
    sort: 'desc'
  }
  getDramaUnList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 基本信息
function touchInfo (record) {
  RefBaseInfo.value.showDrawer(record)
}

// 剧集
function touchSeries (record) {
  RefSeries.value.showDrawer(record)
}

// 入库
function touchStore (record) {
  isLoading.value = true
  storageDrama({
    id: record.id
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('入库成功')
      // 更新列表
      getData()
    } else {
      message.error(res.message || msg)
    }
  })
}

</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

.cover-img {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.cover-title {
  margin-left: 10px;
}
</style>